import React from "react"

import { CartOpenContextProvider } from "./src/components/context/cartOpenContext"
import { ReferrerContextProvider } from "./src/components/context/referrerContext"

export const wrapRootElement = ({ element }) => {
  return (
    <ReferrerContextProvider>
      <CartOpenContextProvider>{element}</CartOpenContextProvider>
    </ReferrerContextProvider>
  )
}

// const Wrapper = ({ element }) => {
//   return <WrapRootElement element={element} />
// }

// exports.wrapRootElement = Wrapper
